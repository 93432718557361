<template>
  <div class="Logo-container">
    <el-image class="Logo-Img" :src="require(`@/assets/${LogoUrl}`)" fit="contain" @click="navigateHome"
              lazy></el-image>
    <div class="Info-container">
      <p v-text="info"></p>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "LogoBlock",
  props: {
    LogoUrl: {
      type: String,
      required: true,
    },
    info: {
      type: String,
      required: true,
    }
  },
  computed: {},
  data() {
    return {}
  },
  methods: {
    navigateHome() {
      this.goHomePage()
    },
  },
})
</script>

<style scoped lang="scss">

.Logo-container {
  display: flex; /* 启用Flexbox布局 */
  align-items: center; /* 垂直居中对齐子元素 */
  justify-content: start;
}

.Logo-Img {
  flex-shrink: 0; // 不允许缩小
  width: 100px;
  height: 100px;
  border-radius: 12px;
}

.Info-container {
  flex-shrink: 0; // 不允许缩小
  color: #fff;
  font-size: 30px;
  font-weight: bold;
  margin: 10px 20px 10px 10px;
  padding: 5px;
  letter-spacing: 10px;
  white-space: nowrap; // 不换行
}

//@media screen and (max-width: 1550px) {
//  .logo-Info {
//    font-size: 12px;
//  }
//
//}
//@media screen and (max-width: 1650px) {
//  .logo-Info {
//    font-size: 18px;
//  }
//
//}
//
///* 当屏幕宽度小于850px时 */
//@media screen and (max-width: 850px) {
//  /* 在此处添加针对小屏幕设备（如手机）的CSS样式 */
//  flex-direction: column; // 水平布局
//  align-items: center; // 垂直居中对齐
//  .logo-img {
//    width: 45px;
//    height: 45px;
//  }
//  .logo-Info {
//    margin: 0;
//    padding: 0;
//    color: #fff;
//    font-size: 15px;
//    font-weight: bold;
//  }
//}

</style>