<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "AboutInfo",
  props: {
    phone: {
      type: String,
      default: '目前暂无',
      required: true,
    },
    emil: {
      type: String,
      default: '目前暂无',
      required: true,
    }
  }
})
</script>

<template>
  <div class="contactUs-container">
    <div class="contact-info">
      <i class="el-icon-message"></i>
      <span> 在线客服：{{ emil }}</span>
    </div>
    <div class="contact-info">
      <i class="el-icon-phone"></i>
      <span> 服务热线：{{ phone }}</span>
    </div>
  </div>
</template>

<style scoped lang="scss">
.contactUs-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .contact-info {
    white-space: nowrap; // 禁止文本换行
    color: #fff;
    font-size: 15px;
    margin: 8px 0 8px 0;
  }
}
</style>