<template>
  <!--尾页界面-->
  <el-footer class="el-footer">
    <el-container class="footer-container">
      <el-row class="footer-row">
        <el-col class="Menus-block" v-for="(menuItem,index) in MenuItems" :key="index">
          <P>
            {{ menuItem.title }}
          </P>
          <ul>
            <li v-for="(link,index) in menuItem.links" :key="index">
              <router-link :to="link.path">{{ link.name }}</router-link>
            </li>
          </ul>
        </el-col>


        <el-col class="Menus-Info" style="margin-left: 20px;justify-content: start">
          <P>
            关于我们
          </P>
          <div class="col-about">
            <el-image :src="require('@/assets/img.png')" fit="contain" class="phone-img"></el-image>
            <div class="info-col">
              <p>
                联系电话：{{ phoneNumble }}
              </p>
              <p>
                地址：{{ addressInfo }}
              </p>
            </div>
          </div>


          <div class="col-about">
            <div>
              <el-image :src="require(`@/assets/${weChatUrl}`)" fit="contain" class="wx-img"></el-image>
              <p>请关注微信公众号</p>
            </div>
          </div>


        </el-col>
      </el-row>

    </el-container>
    <div class="footer-bottom">
      <bottom-page :msg="statement"/>
    </div>

  </el-footer>
</template>


<script>
import bottomPage from '@/components/页脚/页脚声明/bottomPage.vue';

export default {
  name: "PageFooter",
  components: {
    bottomPage
  },
  data() {
    return {
      weChatUrl: 'WeChat.png',
      statement: ["杭州万盘尖信息科技有限公司 企业信息化建设一站式服务商", "浙ICP备2024-123456号", "因为专注，所以我们自信可以给企业提供很专业的指导，万盘尖不管是现在还是将来，承诺为客户提供最优质的服务和最佳的解决方案。"],
      phoneNumble: '0571-88195430',
      addressInfo: '浙江省杭州市钱塘区白杨街道6号大街260号3幢302室',
      MenuItems: [{
        title: '专家',
        links: [
          {name: '黄作育', path: '/'},
          {name: '司圣勇', path: '/'},
          {name: '李鹏飞', path: '/'},
          {name: '郭伟', path: '/'},
          {name: '测试名称', path: '/'},
        ],
      },
        {
          title: '产品',
          links: [
            {name: 'MRP', path: '/'},
            {name: 'MES', path: '/'},
            {name: 'ERP', path: '/'},
          ]
        },
        {
          title: '行业',
          links: [
            {name: 'SMT', path: '/'},
            {name: '线束', path: '/'},
            {name: '医疗', path: '/'},
            {name: '汽车零部件', path: '/'},
            {name: 'IOT数采', path: '/'},
            {name: '其他', path: '/'},
          ]
        },
        {
          title: '新闻',
          links: [
            {name: '业内动态', path: '/'},
            {name: '市场行情', path: '/'},
            {name: '公司动态', path: '/'},
            {name: '了解更多', path: '/'},
          ]
        }],
    };
  },
  methods: {
    async fetchData() {
      await this.fetchDataGlobal(this, this.$options.name);
      await this.getFooterMenus();
    },
    async getFooterMenus() {
      this.$axios.get('/getFooterMenu')
          .then((res) => {
            if (Array.isArray(res.data)) {
              this.MenuItems = res.data;
            } else {
              console.log('getFooterMenu接口返回数据格式错误');
            }
          })
          .catch((error) => {
            console.log(error);
          });
    }
  },
  async created() {
    await this.fetchData();
  }
};
</script>

<style lang="scss" scoped>
@import "PageFooter";
</style>