<template>
  <el-container>
    <!-- 导航 -->
    <PageHeader/>
    <el-main>
      <transition name="el-fade-in">
        <router-view></router-view><!--内容-->
      </transition>
    </el-main>
    <PageFooter/>
  </el-container>
</template>

<script>
import PageHeader from '@/components/导航/PageHeader.vue'
import PageFooter from '@/components/页脚/PageFooter.vue'

export default {
  name: 'app',
  components: {
    PageHeader,
    PageFooter
  },
  data() {
    return {}
  },
}
</script>

<!--全局样式设定-->
<style lang="scss">
* {
  margin: 0;
  padding: 0;
}

body {
  width: 100%;
  min-width: 1800px;
}

html {
  font-family: 微软雅黑, Arial, Helvetica, sans-serif;
  scroll-behavior: smooth;
}

.el-container {
  flex-direction: column !important; // 确保子元素垂直排列
}

.el-main {
  padding: 0 !important; // 确保没有内边距干扰内容
  margin: 100px 0 0 0 !important; // 确保没有内边距干扰内容
  height: auto;
  width: 100%;
  //overflow-y: hidden; /* 部分浏览器支持，滚动条会在内容滚动时覆盖在内容之上而不挤占内容空间 */
  //-ms-overflow-style: none; /* IE 和 Edge */
  //scrollbar-width: none; /* Firefox */
}


</style>
