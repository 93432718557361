import Vue from 'vue';
import Vuex from 'vuex';

// 使用 Vuex
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        activeIndex: '0', selTagInit: ''
    }, mutations: {
        setActiveIndex(state, index) {
            localStorage.setItem('activeIndex', index);
            state.activeIndex = index;
        }, setSelTagInit(state, selTagInit) {
            state.selTagInit = selTagInit;
        }
    }, actions: {}, getters: {}, modules: {},
});


