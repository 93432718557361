import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index.js';

Vue.use(VueRouter);

//跳转路由配置
const routes = [//主页
    {
        path: '/',
        name: 'HomePage',
        component: () => import('@/views/首页/HomePage.vue'),
        meta: {index: '1', title: '万盘尖信息'},
    }, //关于我们
    {
        path: '/about',
        name: 'aboutPage',
        component: () => import('@/views/关于我们/aboutPage.vue'),
        meta: {index: '5', title: '关于万盘尖'},
    }, //联系我们
    {
        path: '/cooperationIntention',
        name: 'cooperationIntention',
        component: () => import('@/views/联系我们/cooperationIntention.vue'),
        meta: {index: '5', title: '与我们联系'},
    }, //解决方案列表
    {
        path: '/SolutionPageItems',
        name: 'SolutionPageItems',
        component: () => import('@/views/解决方案/集合/SolutionPageItems.vue'),
        meta: {index: '2', title: '解决方案'},
        props: true,
    }, {
        path: '*', component: () => import('@/views/异常页/ErrPage.vue'), props: route => ({
            errorCode: 404, // 默认为404
            errorMessage: route.query.message || '未找到该资源',
        }), meta: {title: '资源页面未找到'},
    },];

const router = new VueRouter({
    mode: 'history', base: process.env.BASE_URL, routes, scrollBehavior(to, from, savedPosition) {
        return savedPosition || {x: 0, y: 0};
    },
});


router.beforeEach((to, from, next) => {
    document.title = to.meta.title || 'Default Title';
    next();
});


router.afterEach((to, from) => {
    console.log("从", from.path, "跳转到", to.path);
    const index = to.meta.index || '1'; // 确保有默认值或处理未设置的情况
    store.commit('setActiveIndex', index);
});

export default router;



