<template>
  <el-container>
    <el-row class="footer-bottom">
      <el-col>
        <p v-for="(str,index) in msg" :key="index">
          {{ str }}
        </p>
      </el-col>
    </el-row>
  </el-container>
  <!--  页脚界面授权声明-->
</template>

<script>
export default {
  name: 'bottomPage',
  props: {
    msg: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  },
  methods: {}
}
</script>


<style lang="scss" scoped>
@import "bottomPage";
</style>