<template>
  <div class="Search-container">
    <el-autocomplete
        class="search-input"
        v-model="selText"
        :fetch-suggestions="querySearch"
        placeholder="请输入搜索内容"
        :trigger-on-focus="false"
    ></el-autocomplete>
    <div class="Search-but">
      <el-button icon="el-icon-search" @click="Search" class="search-btn" circle></el-button>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "SearchBlock",
  data() {
    return {
      selText: '',
      restaurants: [
        {"value": "MRP"},
        {"value": "ERP"},
        {"value": "APS"},
        {"value": "MES"}
      ],
    }
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      this.$axios.get('/getSearchDate').then(response => {
        this.restaurants = response.data;
      }).catch(error => {
        console.log(error);
      });
    },
    querySearch(queryString, cb) {
      const results = queryString ? this.restaurants.filter(this.createFilter(queryString)) : this.restaurants;
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return restaurant.value.toLowerCase().includes(queryString.toLowerCase());  // 使用 includes 替代 indexOf === 0
      };
    },
    Search() {
      if (this.selText === '') {
        this.showMessage('无法搜索空内容, 请重新输入!');
        this.selText = '';
        return;
      }
      const exists = this.restaurants.some(restaurant => restaurant.value === this.selText);
      if (exists) {
        if (this.$router.currentRoute.path === '/SolutionPageItems') {
          this.$store.commit('setSelTagInit', this.selText);
          return;
        }
        this.$router.replace('/SolutionPageItems');
      } else {
        console.log("搜索内容不在列表中:", this.selText);
        this.showMessage('搜索内容不存在, 请重新输入!');
      }
    },
  },
})
</script>

<style scoped lang="scss">

.Search-container {
  display: flex; /* 启用Flexbox布局 */
  align-items: center; /* 垂直居中对齐子元素 */
  justify-content: start;
  height: 100%;
  min-width: 250px; // 设置最小宽度防止内容压缩导致换行
}

//搜索输入框
.search-input {
  min-width: 100px;
}

//搜索点击框
.Search-but {
  margin-left: 15px;
}


</style>