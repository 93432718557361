<template>
  <el-submenu v-if="item.children && item.children.length > 0" :key="item.index" :index="item.index">
    <template slot="title">
      <span :class="{ 'is-disabled': !item.display }">{{ item.title }}</span>
    </template>

    <MenuItems
        v-for="child in item.children"
        :key="child.index"
        :item="child"
        :activeIndex="activeIndex"
        @update-active-index="updateActiveIndex(child)"
    />
  </el-submenu>
  <el-menu-item
      v-else
      :key="item.index"
      :index="item.index"
      :disabled="!item.display"
      @click="updateActiveIndex(item)"
  >
    {{ item.title }}
  </el-menu-item>
</template>

<script>

export default ({
  name: 'MenuItems',
  props: {
    item: {
      type: Object,
      required: true,
    },
    activeIndex: {
      type: String,
      default: '1',
    },
  },
  methods: {
    updateActiveIndex(item) {
      if (this.item.display) {
        console.log('点击了菜单项', item);
        this.$emit('update-active-index', item);
        this.$store.commit('setActiveIndex', item.index);  // 更新 Vuex 中的 activeIndex
      }
    },
  }
})
</script>


<style scoped lang="scss">
@import './Menu.scss';
</style>