import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en'
import store from './store';
import http from './common/http';
import router from './router'
import 'element-ui/lib/theme-chalk/index.css';


Vue.use(ElementUI, {locale})
Vue.prototype.$axios = http;
Vue.config.productionTip = false

// 添加一个全局方法，用于导航回首页
Vue.prototype.goHomePage = function () {
    // 检查当前路由是否已经是根路由
    if (this.$route.path === '/') {
        // 如果是，滚动到页面顶部
        window.scrollTo({
            top: 0, behavior: 'smooth'  // 这会使滚动效果平滑
        });
    } else {
        // 如果不是，导航到根路由
        this.$router.push('/');
    }
};

Vue.prototype.showMessage = function (message) {
    const h = this.$createElement;
    this.$message({
        message: h('p', null, [h('span', null, message),])
    });
}

Vue.prototype.getPageConf = async function (name) {
    return new Promise((resolve, reject) => {
        try {
            this.$axios.get('/getWebConf', {
                params: {
                    strPageView: name,
                }
            })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        } catch (e) {
            reject(e);
        }
    });
}

Vue.prototype.fetchDataGlobal = async function (vm, componentName) {
    try {
        const response = await vm.getPageConf(componentName);
        for (const key in response.data) {
            if (key in vm) {
                const items = response.data[key];
                if (Array.isArray(items)) {
                    if (Array.isArray(vm[key])) {
                        vm[key] = items;
                    } else if (Array.isArray(vm[key]) === false) {
                        vm[key] = items[0];
                    } else {
                        console.error(`数据项${key}的数组为空`);
                    }
                } else {
                    console.error(`数据项${key}的值不是数组`);
                }
            }
        }
    } catch (e) {
        vm.showMessage(e.message);
    }
};


Vue.prototype.fetchInitData = async function (url, vm) {
    try {
        this.$axios.get(url)
            .then((res) => {
                if (Array.isArray(vm) && Array.isArray(res.data)) {
                    vm = res.data;
                } else {
                    console.log(url, '接口返回数据格式错误');
                }
            })
            .catch((error) => {
                console.log(error);
            });
    } catch (e) {
        this.showMessage(e.message);
    }
};

new Vue({
    store, router, render: h => h(App),
}).$mount('#app')

