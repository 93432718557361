import axios from 'axios';

const apiBaseURL = process.env.NODE_ENV === 'development' ? 'http://localhost:51397/OW' : 'http://47.114.2.19:9092/OW'; // 请替换为实际生产环境地址

const http = axios.create({
    baseURL: apiBaseURL, // 基础URL，可以根据环境变量动态更改
    timeout: 50000, // 请求超时时间
    headers: {
        'Content-Type': 'application/json',
    },
});


// 请求拦截器
http.interceptors.request.use(config => {
    console.log('请求接口地址:', config.url, '请求参数参数:', config.params || config.data);
    return config;
}, error => {
    return Promise.reject(error);
});


// 响应拦截器
http.interceptors.response.use(response => {
    console.log('响应接口地址:', response.config.url, '响应接口响应数据:', response.data);
    return response.data;
}, error => {
    console.log('错误信息:', error.message);
    const {status} = error.response;
    if (status === 201) {
        console.error('错误:', error.message);
        this.showMessage(error.message);
    } else if (status === 400) {
        console.error('Bad Request:', error.message);
    } else if (status === 401) {
        console.error('Unauthorized:', error.message);
    } else if (status === 404) {
        console.error('Not Found:', error.message);
    } else if (status >= 500) {
        console.error('Server Error:', error.message);
    } else {
        console.error('Unexpected Error:', error.message);
    }
    return Promise.reject(error);
});


//不带参数的GET
// http.get('/your-endpoint')
//     .then(response => {
//         console.log('GET Response:', response);
//         // 处理成功的响应
//     })
//     .catch(error => {
//         console.error('GET Error:', error);
//         // 处理错误
//     });

//带参数的GET
// http.get('/your-endpoint', {
//     params: {
//         key1: 'value1',
//         key2: 'value2'
//     }
// })
//     .then(response => {
//         console.log('GET with Params Response:', response);
//         // 处理成功的响应
//     })
//     .catch(error => {
//         console.error('GET with Params Error:', error);
//         // 处理错误
//     });


//POST 不带参数
// http.post('/your-endpoint', {})
//     .then(response => {
//         console.log('POST Response:', response);
//         // 处理成功的响应
//     })
//     .catch(error => {
//         console.error('POST Error:', error);
//         // 处理错误
//     });

//POST 带参数
// http.post('/your-endpoint', {
//     key1: 'value1',
//     key2: 'value2'
// })
//     .then(response => {
//         console.log('POST with Data Response:', response);
//         // 处理成功的响应
//     })
//     .catch(error => {
//         console.error('POST with Data Error:', error);
//         // 处理错误
//     });

export default http;

