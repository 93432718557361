<template>
  <!--  导航界面-->
  <el-header>
    <el-row type="flex" justify="right" class="header-row">

      <!-- Logo-->
      <el-col class="logo-and-info">
        <LogoBlock :info="logoInfo" :logo-url="logoUrl"/>
      </el-col>

      <!--      导航栏-->
      <el-col class="Menu-container">
        <el-menu
            menu-trigger="click"
            :default-active="activeIndex"
            class="menu"
            mode="horizontal"
            background-color="#545c64"
            text-color="#fff"
            active-text-color="#ffd04b"
        >
          <Menu
              v-for="(item) in menuItems"
              :key="item.index"
              :item="item"
              :activeIndex="activeIndex"
              @update-active-index="updateActiveIndex"
          />
        </el-menu>
      </el-col>


      <!--          搜索-->
      <el-col class="search-col">
        <Search/>
      </el-col>


      <!--联系方式-->
      <el-col class="Info-col">
        <AboutInfo :phone="phone" :emil="emil"/>
      </el-col>


    </el-row>
  </el-header>
</template>


<script>
import {mapState} from 'vuex';
import Menu from "@/components/导航/Menu/Menu.vue";
import LogoBlock from "@/components/导航/Logo/Logo.vue";
import Search from "@/components/导航/Search/Search.vue";
import AboutInfo from "@/components/导航/Info/AboutInfo.vue";

export default {
  name: 'PageHeader',
  watch: {
    activeIndex(newVal, oldVal) {
      console.log(`选中的 activeIndex 的值从 ${oldVal} 变为了 ${newVal}`)
    }
  },
  props: {},
  components: {
    Menu,
    LogoBlock,
    Search,
    AboutInfo
  },
  data() {
    return {
      logoUrl: 'images/Logo/Logo.jpg',
      logoInfo: '万盘尖信息',
      //菜单
      menuItems: [{index: '1', title: '首页', path: '/', display: false,},
        {
          index: '2', title: '解决方案', display: false, children: [
            {index: '2-1', title: 'MRP', display: false, path: '/SolutionPageItems'},
            {index: '2-2', title: 'ERP', display: false, path: '/SolutionPageItems'},
            {index: '2-3', title: 'APS', display: false, path: '/SolutionPageItems'},
            {index: '2-4', title: 'MES', display: false, path: '/SolutionPageItems'},
          ]
        },
        {index: '3', title: '客户', path: '/Test', display: false},
        {index: '4', title: '新闻', path: '/Test', display: false},
        {index: '5', title: '关于我们', path: '/about', display: false}],
      emil: 'wpjsystem.com',
      phone: '0571-88195430',
    };
  },
  mounted() {
    const savedIndex = localStorage.getItem('activeIndex');
    if (savedIndex) {
      this.$store.commit('setActiveIndex', savedIndex);
    }
  },
  computed: {
    ...mapState([
      'activeIndex',
    ])
  },
  methods: {
    // 更新逻辑
    updateActiveIndex(item) {
      console.log("点击导航参数:" + item)
      if (item.display) {
        if (this.$route.path !== item.path) {
          this.$router.push(item.path);
        }
        this.$store.commit('setSelTagInit', '');
        if (item.path === '/SolutionPageItems') {
          this.$store.commit('setSelTagInit', item.title);
          console.log("提交完成状态:" + item.title);
        }
        this.$store.commit('setActiveIndex', item.index);
        localStorage.setItem('activeIndex', item.index);
      }
    },
    async getMenus() {
      this.$axios.get('/getMenu').then(response => {
        // 请求成功，处理返回的数据
        this.menuItems = response.data;
      }).catch(error => {
        // 请求失败，处理错误
        console.log(JSON.stringify(error.message))
      });
    },
    async fetchData() {
      await this.fetchDataGlobal(this, this.$options.name);//获取本界面配置
      await this.getMenus();//获取菜单
    }
  },
  async created() {
    await this.fetchData();
  }
}
</script>

<style lang="scss" scoped>
@import "PageHeader";
</style>
